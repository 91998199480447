import React, { Component } from "react";
import styled from "@emotion/styled";
import Draggable from "react-draggable"; // The default
import Header from "../molecules/windowHeader";
import IframeWindow from "./iframeWindow";
const WindowContainer = styled.div`
  position: absolute;
  z-index: ${({ index }) => index + 100};
  background-color: #f1f1f1};
  border: 1px solid #ededed;
  text-align: center;
  max-width: 60%;
  max-height: 70%;
  width: 800px;
  height: 700px;
  border-radius: 0.4rem;
  overflow: hidden;
  left: ${({ offsetIndex }) => 20 * offsetIndex + window.innerWidth / 4}px;
  top: ${({ offsetIndex }) => 25 * offsetIndex + (window.innerHeight / 10)}px;
  -webkit-box-shadow: 0px 6px 5px 0px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0px 6px 5px 0px rgba(0, 0, 0, 0.19);
  box-shadow: 0px 6px 5px 0px rgba(0, 0, 0, 0.19);
`;

const ContentContainer = styled.div`
  position: absolute;
  z-index: ${({ index }) => index + 100};
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-x: hidden;
`;

class Window extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getContent = () => {
    switch (this.props.window) {
      default:{
        return <IframeWindow src={this.props.window} inFocus={this.props.inFocus}/>;
      }
    }
  };

  render() {
    return (
      <Draggable
        handle=".handle"
        bounds="parent"
        onStart={this.props.onMouseDown}
      >
        <WindowContainer {...this.props} className="handle">
            <Header
              onClose={() => this.props.onClose(this.props.offsetIndex)}
              title={this.props.window}
            />
          <ContentContainer>{this.getContent()}</ContentContainer>
        </WindowContainer>
      </Draggable>
    );
  }
}

export default Window;
