import React, { Component } from "react";
import NavBar from "../molecules/navBar";
import Dock from "../molecules/dock";
import styled from "@emotion/styled";
import Window from "./window";
import DesktopIcon from "../molecules/desktopIcon";
import PostIt from "./postIt";
import PostItText from "../assets/postitText"
const DesktopContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url("https://9to5mac.com/wp-content/uploads/sites/6/2018/06/mojave-day.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  overflow: hidden;
`;

const DraggableArea = styled.div`
  height: 100%;
  width: 100%;
  padding: 1.5rem 0 0 0;
  display: inline-flex;
  flex-flow: column wrap;
  align-content: flex-start;
`;

class Desktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windows: [
        // { title: "Interests", src: "https://i.ibb.co/0yvx123/kissclipart-apple-news-logo-clipart-apple-news-545e27aae4682fa4.png"},
        { title: "Projects",  src: "https://codelikethis.com/lessons/client-side-coding/postman-logo-512.png"},
        { title: "Experience", src: "https://3.bp.blogspot.com/-raztqT09w_s/WgmGvtgaNnI/AAAAAAAAB8A/p_evEmpNWZcVteICsaUy_cRjSU3NSOdCwCLcBGAs/s1600/code.png"},
        { title: "Education", src: "https://cdn3.iconfinder.com/data/icons/popular-services-brands-vol-2/512/stackoverflow-512.png" },
        { title: "About Me", src: "/logo192.png" },
      ],
      stack: new Set(),
    };

    this.closeWindow = this.closeWindow.bind(this);
    this.openWindow = this.openWindow.bind(this);
  }

  componentDidMount() {
    this.initStack();
  }
  componentDidUpdate(prevProps, prevState) {
    this.initStack();
  }

  initStack(newWindow = null) {
    if (newWindow) {
      let tempSet = new Set(this.state.stack);
      tempSet.add(newWindow);
      this.setState({ stack: tempSet });
    } else if (this.state.stack.size !== this.state.windows.length) {
      this.setState({
        stack: new Set([...Array(this.state.windows.length).keys()]),
      });
    }
  }

  updateStack(key) {
    let tempSet = new Set(this.state.stack);
    tempSet.delete(key);
    tempSet.add(key);
    this.setState({ stack: tempSet });
  }

  async closeWindow(x) {
    let tempWindow = this.state.windows;
    tempWindow[x].hidden = true;
    let newStack = [...this.state.stack]
    let tempPage = newStack.pop()
    newStack.unshift(tempPage)
    let tempSet = new Set(newStack);
    this.setState({ windows: tempWindow, stack: tempSet });
  }

  async openWindow(id) {
    let tempWindow = this.state.windows;
    for (let i in tempWindow) {
      if (tempWindow[i].title === id) {
        tempWindow[i].hidden = false;
        this.updateStack(parseInt(i))
      }
    }
    this.setState({ windows: tempWindow });
  }

  render() {
    let desktopLabels = [{ label: "Skills" }, { label: "Goals" }, { label: "Projects" }, { label: "Mockup", src:"https://i.ibb.co/PDhbb4v/Untitled.png" } , { label: "DB-Diagram", src:"https://i.ibb.co/Xz39nHw/Untitled.png" }];
    let windows = this.state.windows.map((v, k) => (
      <Window
        key={k}
        window={v.title}
        index={[...this.state.stack].indexOf(k)}
        offsetIndex={k}
        onMouseDown={() => this.updateStack(k)}
        onClose={(x) => this.closeWindow(x)}
        hidden={v.hidden}
        inFocus={this.state.windows[[...this.state.stack].pop()]?.title==v.title}
      />
    ));

    let desktopIcons = desktopLabels.map((v, k) => (
      <DesktopIcon
        src={v.src || "https://i.ibb.co/6Hq2gzS/Untitled-copy.png"}
        label={v.label}
        key={k}
        index={k}
      />
    ));
    return (
      <DesktopContainer>
        <NavBar
          window={this.state.windows[[...this.state.stack].pop()]?.title}
        />
        <DraggableArea>
          {windows}
          {desktopIcons}
          <PostIt text={PostItText.text2()} id={1}/>
        </DraggableArea>
        <Dock
          windows={this.state.windows}
          openPage={(x) => this.openWindow(x)}
        />
      </DesktopContainer>
    );
  }
}

export default Desktop;
