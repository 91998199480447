import React from "react";
import styled from "@emotion/styled";

const Iframe = styled.iframe`
  height: 100%;
  width: 100%;
  border: none;
  ${({inFocus})=>inFocus?"":"pointer-events: none"}

`;

function iframeWindow(props) {
  return (
    <>
      <Iframe src={props.src} inFocus={props.inFocus} title="Iframe Example"></Iframe>
    </>
  )
}

export default iframeWindow;
