import React from "react";
import styled from "@emotion/styled";
import Draggable from "react-draggable"; // The default

const PostitContainer = styled.div`
  height: 17rem;
  width: 17rem;
  background-image: url("https://i.ibb.co/Hz85db5/kindpng-691250.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  z-index: 30;
  padding: 1rem;
  -webkit-box-shadow: 0px 6px 5px 0px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0px 6px 5px 0px rgba(0, 0, 0, 0.19);
  box-shadow: 0px 6px 5px 0px rgba(0, 0, 0, 0.19);
  overflow-y: hidden;
  margin: 1rem;
`;

const Text = styled.div`
  width: 100%;
  font-family: Comic Sans MS;
  font-size: ${({length})=>length>400?"10px;":"15px;"}
`;
function PostIt(props) {
  let length = props.text.length
  return (
    <Draggable bounds="parent">
      <PostitContainer index={props.id}>
          {props.text.split("<br/>").map((item, i) => {
            return <Text key={i} length={length}>{item}</Text>;
          })}
   
      </PostitContainer>
    </Draggable>
  );
}

export default PostIt;
