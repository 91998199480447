import React from 'react';
import logo from './logo.svg';
import './App.css';
import Desktop from './components/organisms/desktop'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

function App() {
  return (
    <div className="App">
      <BrowserView>
      <Desktop/>
      </BrowserView>
      <MobileView>
        Please visit this page on a browser for a better viewing experience.
      </MobileView>
    </div>
  );
}

export default App;
