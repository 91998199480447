import React, {useState, useEffect} from 'react';
import moment from 'moment';

function Time() {
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 1000);
        return () => {
          clearInterval(interval);
        };
      }, []);

    return (
        <>
            {moment(time).format("ddd HH:mm:ss")}
        </>
    )
}

export default Time;