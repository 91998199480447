import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

const HeaderContainer = styled.div`
  height: 2rem;
  width: 100%;
  background-color: rgba(242, 242, 242, 0.5);
  display: flex;
  cursor: pointer;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 0 0.4rem;
`;

const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0.4rem 0 auto;
`;

const Center = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 8px;
  flex-direction: row;
  align-items: center;
  margin: auto;
`;

const Circle = styled.div`
  height: 13px;
  width: 13px;
  background-color: ${({ color }) => color};
  margin: 0 0.3rem 0 0.3rem;
  position: relative;
  border-radius: 50px;
`;

const ToolTip = styled.div`
  font-size: 10px;
  font-weight: 100;
  opacity: 0.2;
  animation: 10s ease-out 0s 1 slideInFromLeft;

  @keyframes slideInFromLeft {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
`;

const Title = styled.div`
  font-size: 13px;
  font-weight: 100;
  opacity: 0.8;
`;

const Icon = styled.img`
  height: ${({ height }) => height || "6px"};
  width: ${({ height }) => height || "6px"};
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;


function WindowHeader(props) {
  return (
    <HeaderContainer>
      <LeftContainer>
        <Circle color={"#FB3C3F"} onClick={() => props.onClose()}>
          <Icon  src={"/images/cross.png"} />
        </Circle>
        <Circle color={"#FEB926"}>
          <Icon src={"/images/minus.png"} />
        </Circle>
        <Circle color={"#29CA33"}>
          <Icon src={"/images/expand.png"} />
        </Circle>
      </LeftContainer>
      <Center>
        <Title>{props.title}</Title>
      </Center>
      <Right>
        <ToolTip>Drag here to move window</ToolTip>
      </Right>
    </HeaderContainer>
  );
}

export default WindowHeader;
