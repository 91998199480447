import React from "react";
import styled from "@emotion/styled";
import Time from "../atoms/time";
const NavBarContainer = styled.div`
  width: 100%;
  height: 1.5rem;
  background: #14192f;
  background: rgb(234, 244, 246);
  opacity: 0.95;
  -webkit-box-shadow: 0px 6px 5px 0px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0px 6px 5px 0px rgba(0, 0, 0, 0.19);
  box-shadow: 0px 6px 5px 0px rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: row;
`;

const LeftSection = styled.div`
  height: 100%;
  padding: 0 1rem 0 0.5rem;
  justify-self: start;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RightSection = styled.div`
  height: 100%;
  padding: 0 1rem 0 1rem;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Text = styled.div`
  margin: 0 0.6rem 0 0.6rem;
  font-size: 15px;
  font-weight: ${({ bold }) => (bold ? 700 : 540)};
`;

const NavIcon = styled.img`
  margin: 0 0.6rem 0 0.6rem;
  max-height: 14px;
  max-width: auto;
  object-fit: cover;
`;

const ItemContainer = styled.div`
  margin: 0 0.6rem 0 0.6rem;
  max-height: 16px;
`;

function NavBar(props) {
  return (
    <NavBarContainer>
      <LeftSection>
        <NavIcon
          src={"https://freeiconshop.com/wp-content/uploads/edd/pear-solid.png"}
        />
        <Text bold={true}>{props.window}</Text>
        <Text>File</Text>
        <Text>Edit</Text>
        <Text>Selection</Text>
      </LeftSection>
      <RightSection>
        <NavIcon src={"https://i.ibb.co/TqN4DvK/hiclipart-com.png"} />
        <NavIcon
          src={
            "https://i.ibb.co/JpsxgBf/348d21da2719ddfef7e6dc69786ea809-copy.png"
          }
        />
        <ItemContainer>
          <Time />
        </ItemContainer>
        <Text>Kenneth Kreindler</Text>
        <NavIcon src={"https://cdn.onlinewebfonts.com/svg/img_189947.png"} />
      </RightSection>
    </NavBarContainer>
  );
}

export default NavBar;
