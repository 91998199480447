import React from "react";
import styled from "@emotion/styled";
import Draggable from "react-draggable"; // The default

const Icon = styled.img`
  height: 4.5rem;
  width: 4.5rem;
`;

const ElementContainer = styled.div`
  width: fit-content;
  position: absolute;
  right: 30px;
  top: ${({index})=>(index*110)+ 50}px;
  z-index: 5;
`;

const Label = styled.div`
  color: #EEEEEE;
  font-size: 11px;
  font-weight: 700;
`;

function DesktopIcon({ src, label, index }) {
  return (
    <Draggable handle="strong" bounds="parent">
      <ElementContainer index={index}>
        <strong><Icon draggable={false} src={src}/></strong>
        <Label>{label}</Label>
      </ElementContainer>
    </Draggable>
  );
}

export default DesktopIcon;
