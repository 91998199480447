import React from "react";
import styled from "@emotion/styled";
import "./dock.css";

const DockContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

function Dock(props) {
  let windows = props.windows;
  let additionalApps = windows.map((v, k) => (
    <>
      <li>
        <span>{v.title}</span>
        <a onClick={()=>props.openPage(v.title)}>
          <img src={v.src||"https://upload.wikimedia.org/wikipedia/commons/thumb/9/91/Octicons-mark-github.svg/1024px-Octicons-mark-github.svg.png"} />
        </a>
      </li>
    </>
  ));
  return (
    <DockContainer>
      <div id="dock-container">
        <ul>
          <li>
            <span>GitHub</span>
            <a href="https://github.com/KDKHD" target="blank">
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/91/Octicons-mark-github.svg/1024px-Octicons-mark-github.svg.png" />
            </a>
          </li>
          <li>
            <span>LinkedIn</span>
            <a href="https://www.linkedin.com/in/kennethkreindler/" target="blank">
              <img src="https://cdn4.iconfinder.com/data/icons/social-messaging-ui-color-shapes-2-free/128/social-linkedin-circle-512.png" />
            </a>
          </li>
          <li>
            <span>CV</span>
            <a href="/CV-min.pdf" target="blank">
              <img src="https://i.ibb.co/KqRZhd6/Untitled.png" />
            </a>
          </li>
          {additionalApps}
        </ul>
      </div>
    </DockContainer>
  );
}

export default Dock;
